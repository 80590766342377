import React, { history, useEffect, useState, useMemo } from "react";
import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useNavigate, useLocation } from 'react-router-dom';

const Option = (props) => {
    return (
        <div>
            {
                //console.log("results=="+JSON.stringify(props))
            }
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


const AddUser = ({ editUser, formActionSet, onTrigger, onClose }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const { reset } = useForm();
    const pageNum = 1;
    const pageSize = 100;
    const [emailValidationMessage, setEmailValidationMessage] = useState('');
    const [projectManagerListInfo, setProjectManagerListInfo] = useState([]);
    const [multiProjectManagers, setmultiProjectManagers] = useState([]);
    const [managersRequestArray, setManagersRequestArray] = useState([]);
    const [optionManagersSelected, setoptionManagersSelected] = useState([]);
    const [userDesignations, setUserDesignations] = useState([]);
    const [fullCompanies, setFullCompanies] = useState([]);
    const [showProjectList, setShowProjectList] = useState([]);
    const [managerListInfo, setManagerListInfo] = useState([]);
    const [userdata, setUserData] = useState([]);
    const [showLoader, setShowLoader] = useState(0);


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        designation: '',
        role: '',
        company: '',
        employeeId: '',
        projects: [],
        reportingManager: [],
    });



    const handleClose = () => {
        // This function will be called when you close the modal
        if (onClose) {
            onClose();  // Trigger the parent component's `handleCloseModal` to reset state
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });
    const location = useLocation();
    const pathLocation = location.pathname;
    const pathstring = location.search;
    const [myAdminCompany, setMyAdminCompany] = useState('');


    useEffect(() => {

        setShowLoader(0);
        getUserDesignations();
        getCompanyList(1);
        getProjectsByCompany(sessionStorage.getItem('adminCompany'));
        //getProjectList('',1,'asc', pageNum,pageSize);
        //handleProjectChange('');

        if (!isEmpty(editUser) && formActionSet === 'edit') {
            //edit
            $('#addProjectBody').hide();
            $('#editUserBody').show();
            console.log(editUser);
            getUserInfo(editUser);
        }
        else {
            setIsEditMode(false);
            var addCompany = sessionStorage.getItem("adminCompany");
            setMyAdminCompany(addCompany);
            getUsersWithCompany(addCompany, '');

            $('#addProjectBody').show();
            $('#editUserBody').hide();
        }
        $('#successMessageUser').hide();
        $('#updatesuccessMessageUser').hide();
        $('#failuresuccessMessageUser').hide();
        $('#validationMessage').hide();
        $('#editvalidationMessage').hide();

    }, [editUser]);

    const [userstat, setUserStat] = useState(1);
    const [userManager, setUserManager] = useState('');
    const [reportingManagerId, setReportingManagerId] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);

    function getUserInfo(userid) {
        setShowLoader(1);
        services.getUserById(userid).then((res) => {
            if (res.data.type === 'success') {
                setUserData(res.data.data);
                var userResponse = res.data.data;

                $('#userfirst_name').val(userResponse.first_name);
                $('#userlast_name').val(userResponse.last_name);
                $('#user_email').val(userResponse.email);
                $('#user_role').val(userResponse.role_id);
                $('#user_designation').val(userResponse.designation_id);
                $('#employeeid').val(userResponse.employee_id);
                $('#companyname').val(userResponse.company_id);
                setMyAdminCompany(userResponse.company_id);
                setUserStat(userResponse.status);
                setIsEditMode(true);
                getProjectsByCompany(userResponse.company_id);
                getUsersWithCompany(userResponse.company_id, userid);

                services.getUsersReportingManager(userid).then((res) => {
                    if (res.data.type === 'success') {
                        for (var m = 0; m < res.data.data.length; m++) {
                            $('#manager_name').val(res.data.data[m].manager.id);
                            setUserManager(res.data.data[m].manager.id);
                            setReportingManagerId(res.data.data[m].id);
                        }
                    }
                })

                if (userResponse.role_id === 3) {
                    services.getProjectUsersByUserIdFromAdmin(userid, 1).then((res) => {
                        if (res.data.type === 'success') {
                            var dummArray = [];
                            var dummArray_label = [];
                            var dummArray_all = [];
                            for (var i = 0; i < res.data.data.length; i++) {
                                if (res.data.data[i].project_user_status === 'assigned') {
                                    dummArray.push(res.data.data[i].project.id.toString());
                                    dummArray_label.push(res.data.data[i].project.name.toString());
                                    dummArray_all.push({ "label": res.data.data[i].project.name, "value": res.data.data[i].project.id });
                                }
                            }

                            setProjectsRequestArray(dummArray);

                            setoptionProjectsSelected(dummArray_all);
                        }
                    })
                }
                if (userResponse.role_id === 2) {
                    services.getProjectManagersByProjectId('', userid, 1, '', '').then((res) => {
                        if (res.data.type === 'success') {
                            var dummArray = [];
                            var dummArray_label = [];
                            var dummArray_all = [];
                            for (var i = 0; i < res.data.data.length; i++) {

                                if (res.data.data[i].project_manager_status === 'assigned') {
                                    dummArray.push(res.data.data[i].project.id.toString());
                                    dummArray_label.push(res.data.data[i].project.name.toString());
                                    dummArray_all.push({ "label": res.data.data[i].project.name, "value": res.data.data[i].project.id });
                                }
                            }

                            setProjectsRequestArray(dummArray);

                            setoptionProjectsSelected(dummArray_all);
                        }
                    })
                }

            }
        });
        setShowLoader(0);
    }

    const getUserDesignations = () => {
        services.getUserDesignations().then((res) => {

            if (res.data.type == 'success') {
                setUserDesignations(res.data.data);
            }
        });
    }

    const getCompanyList = (companystat) => {
        services.getCompanyList(companystat).then((res) => {

            if (res.data.type == 'success') {
                setFullCompanies(res.data.data);
            }
        });
    }

    const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
    }

    const getTitleHead = () => {
        if (!isEmpty(editUser) && formActionSet === 'edit') {
            return 'Edit User';
        }
        else {
            return 'Create New User';
        }
    }

    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        var results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const resetForm = () => {
        $("#entryform1").trigger("reset");
        setDesignationvalidaion(0);
        setUserrolevalidaion(0)
        setCompanyvalidaion(0);
        setEmployeeidvalidaion(0);
        setEmailvalidation(0);
        setLastNamevalidation(0);
        setFirstNamevalidation(0);
        setShowValidation(0);
        setoptionProjectsSelected([]);
        setUserManager('');
        $('#userfirst_name').val('');
        $('#userlast_name').val('');
        $('#user_email').val('');
        $('#user_designation').val('');
        $('#user_role').val('');
        $('#companyname').val('');
        $('#employeeid').val('');
        $('#manager_name').val('');
        editUser = null;
        formActionSet = null;
    };

    useEffect(() => {
        // Attach the event listener to the modal
        const modalElement = document.getElementById('createuser');
        const handleModalHidden = () => resetForm();

        modalElement.addEventListener('hidden.bs.modal', handleModalHidden);

        return () => {
            // Cleanup the event listener when the component unmounts
            modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, []);

    useEffect(() => {
        if (!isOpen) {
            resetForm(); // Reset the form when the modal is closed
        }
    }, [isOpen]);

    function getProjectManagersByProjectId(projectid, managerid, projstat) {
        services.getProjectManagersByProjectId(projectid, managerid, projstat, '', '').then((res) => {

            if (res) {
                var dummArray_default = [];
                var dummArray_ids = [];
                var managerResult = res.data.data;
                if (!isEmpty(managerResult)) {
                    for (var i = 0; i < managerResult.length; i++) {
                        if (managerResult[i].project_manager_status === 'assigned') {
                            dummArray_default.push({ "label": managerResult[i].manager.first_name + ' ' + managerResult[i].manager.last_name, "value": managerResult[i].manager.id });
                            dummArray_ids.push(managerResult[i].manager.id);
                        }
                    }
                }


                setManagersRequestArray(dummArray_ids);
                setoptionManagersSelected(dummArray_default);

            }
        });
    }

    const [showValidation, setShowValidation] = useState(0);
    const [firstNamevalidation, setFirstNamevalidation] = useState(0);
    const [lastNamevalidation, setLastNamevalidation] = useState(0);
    const [emailvalidation, setEmailvalidation] = useState(0);

    const [employeeidvalidaion, setEmployeeidvalidaion] = useState(0);
    const [companyvalidaion, setCompanyvalidaion] = useState(0);
    const [userrolevalidaion, setUserrolevalidaion] = useState(0);
    const [designationvalidaion, setDesignationvalidaion] = useState(0);
    const submitValue = () => {
        var validation = 0;

        if (isEmpty($('#userfirst_name').val())) {
            validation = 1;
            setFirstNamevalidation(1);
        } else {
            setFirstNamevalidation(0);
        }

        if (isEmpty($('#userlast_name').val())) {
            validation = 1;
            setLastNamevalidation(1);
        } else {
            setLastNamevalidation(0);
        }

        const email = $('#user_email').val();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


        if (isEmpty(email)) {
            validation = 1;
            setEmailvalidation(1);
            setEmailValidationMessage('Email address is required.');
        } else if (!email.includes('@')) {
            validation = 1;
            setEmailvalidation(1);
            //setEmailValidationMessage('Email address must include an @ symbol.');
            setEmailValidationMessage('Please enter a valid email address.');
        } else if (!email.split('@')[1] || !email.split('@')[1].includes('.')) {
            validation = 1;
            setEmailvalidation(1);
            //setEmailValidationMessage('Email address must include a domain (e.g., example.com).');
            setEmailValidationMessage('Please enter a valid email address.');
        } else if (!email.split('.')[1]) {
            validation = 1;
            setEmailvalidation(1);
            //setEmailValidationMessage('Email address must include a domain (e.g., example.com).');
            setEmailValidationMessage('Please enter a valid email address.');
        } else {
            setEmailvalidation(0);
            setEmailValidationMessage(''); // Clear the message if valid
        }

        if (isEmpty($('#user_designation').val())) {
            validation = 1;
            setDesignationvalidaion(1);
        } else {
            setDesignationvalidaion(0);
        }

        if (isEmpty($('#user_role').val())) {
            validation = 1;
            setUserrolevalidaion(1);
        } else {
            setUserrolevalidaion(0);
        }

        if (isEmpty($('#companyname').val())) {
            validation = 1;
            setCompanyvalidaion(1);
        } else {
            setCompanyvalidaion(0);
        }

        if (isEmpty($('#employeeid').val())) {
            validation = 1;
            setEmployeeidvalidaion(1);
        } else {
            setEmployeeidvalidaion(0);
        }

        if (validation == 1) {
            setShowValidation(1);
            $('#validationMessage').show();
            return false;
        } else {
            setShowValidation(0);
            setShowLoader(1);
            var myprojectclientidforadd = '';
            if (!isEmpty(editUser) && formActionSet === 'edit') {
                //edit

                const requestObject =
                {
                    "company_id": $('#companyname').val(),
                    "first_name": $('#userfirst_name').val(),
                    "last_name": $('#userlast_name').val(),
                    "email": $('#user_email').val(),
                    "role_id": $('#user_role').val(),
                    "designation_id": $('#user_designation').val(),
                    "employee_id": $('#employeeid').val(),
                    "status": userstat
                }
                var accessTokenIn = sessionStorage.getItem("accToken");
                // const requestOptions = {
                // method: 'PUT',
                // headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                // },
                // body: JSON.stringify(requestObject)
                // };

                //$(".load-wrap").show();
                services.updateUserInformation(requestObject, editUser).then((res) => {

                    if (res.type == 'success') {
                        var myuseridforadd = res.data.id;

                        //if(optionProjectsSelected.length > 0){
                        var userRole = $('#user_role').val();

                        assignProject(editUser, userRole);
                        if (!isEmpty($('#manager_name').val())) {
                            createManager(editUser);
                        } else {
                            deleteManager(reportingManagerId);
                            $("#entryform1").trigger("reset");
                            setIsOpen(false);
                            $("#successMessageUser").show();
                            //$(".loadwrapper").hide();
                            setShowLoader(0);
                            $("#successMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                                $("#successMessageUser").slideUp(500);
                            });
                            setTimeout(function () {
                                $('#closemodal').click();
                                if (typeof onTrigger === 'function') {
                                    // onTrigger('');
                                }

                                if (pathLocation === '/user-management') {
                                   //alert("454")
                                    //navigate('/user-management');
                                    // onTrigger('');

                                    setTimeout(() => {
                                        setShowLoader(0);
                                        //navigate('/user-management');
                                        onTrigger('');
                                    }, 1000);


                                } else {
                                    navigate('/projectDetail');
                                }
                            }, 1000);
                        }

                    }
                    if (res.type == 'error') {
                        $("#entryform1").trigger("reset");
                        setIsOpen(false);
                        $("#failuresuccessMessageUser").html(res.message);
                        //$(".loadwrapper").hide();
                        setShowLoader(0);
                        $("#failuresuccessMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                            $("#failuresuccessMessageUser").slideUp(500);
                        });

                    }
                });
            } else {
                //add
                const requestObject =
                {
                    "first_name": $('#userfirst_name').val(),
                    "last_name": $('#userlast_name').val(),
                    "email": $('#user_email').val(),
                    "role_id": $('#user_role').val(),
                    "designation_id": $('#user_designation').val(),
                    "employee_id": $('#employeeid').val(),
                    "status": 1, // Assuming status is always active for new users
                    "company_id": $('#companyname').val(),
                }



                var accessTokenIn = sessionStorage.getItem("accToken");
                // const requestOptions = {
                // method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                // },
                // body: JSON.stringify(requestObject)
                // };

                setShowLoader(1);
                services.createUserFromAdmin(requestObject).then((res) => {

                    if (res.type == 'success') {
                        var myuseridforadd = res.data.id;

                        if (optionProjectsSelected.length > 0) {
                            var userRole = $('#user_role').val();
                            assignProject(myuseridforadd, userRole);
                            if (!isEmpty($('#manager_name').val())) {
                                createManager(myuseridforadd);
                            } else {
                                $("#entryform1").trigger("reset");
                                setIsOpen(false);
                                $("#successMessageUser").show();
                                //$(".loadwrapper").hide();
                                setShowLoader(0);
                                $("#successMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                                    $("#successMessageUser").slideUp(500);
                                });
                                setTimeout(function () {
                                    $('#closemodal').click();
                                    if (typeof onTrigger === 'function') {
                                        onTrigger('');
                                    }

                                    if (pathLocation === '/user-management') {
                                        navigate('/user-management');
                                    } else {
                                        navigate('/projectDetail');
                                    }
                                }, 1000);
                            }
                        } else {
                            if (!isEmpty($('#manager_name').val())) {
                                createManager(myuseridforadd);
                            } else {
                                $("#entryform1").trigger("reset");
                                setIsOpen(false);
                                $("#successMessageUser").show();
                                //$(".loadwrapper").hide();
                                setShowLoader(0);
                                $("#successMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                                    $("#successMessageUser").slideUp(500);
                                });
                                setTimeout(function () {
                                    $('#closemodal').click();
                                    if (typeof onTrigger === 'function') {
                                        onTrigger('');
                                    }

                                    if (pathLocation === '/user-management') {
                                        navigate('/user-management');
                                    } else {
                                        navigate('/projectDetail');
                                    }
                                }, 1000);

                            }
                        }

                    }
                    if (res.type == 'error') {
                        $("#failuresuccessMessageUser").html(res.message);

                        setShowLoader(0);
                        $("#failuresuccessMessageUser").fadeTo(5000, 500).slideUp(500, function () {
                            $("#failuresuccessMessageUser").slideUp(500);
                        });
                    }
                });
            }
        }
    }

    const createManager = (useridvalue) => {
        const requestObject2 =
        {
            "manager_id": $('#manager_name').val(),
            "user_id": useridvalue
        }

        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions2 = {
        // method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        // },
        // body: JSON.stringify(requestObject2)
        // };
        services.createUserReportingManager(requestObject2).then((res) => {
            if (res.type == 'success') {
                $("#entryform1").trigger("reset");
                setIsOpen(false);
                $("#updatesuccessMessageUser").show();

                setShowLoader(0);
                $("#updatesuccessMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                    $("#updatesuccessMessageUser").slideUp(500);
                });
                setTimeout(function () {
                    $('#closemodal').click();
                    if (typeof onTrigger === 'function') {
                        onTrigger('');
                    }
                    navigate(`${pathLocation}${pathstring}`)

                }, 1000);
            }
            if (res.type == 'error') {
                $("#failuresuccessMessageUser").html(res.message);

                setShowLoader(0);
                $("#failuresuccessMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                    $("#failuresuccessMessageUser").slideUp(500);
                });
            }
        })

    }

    const deleteManager = (reportingManagerId) => {

        services.deleteReportingManager(reportingManagerId).then((res) => {
            if (res.type == 'success') {
                $("#entryform1").trigger("reset");
                setIsOpen(false);
                $("#updatesuccessMessageUser").show();

                setShowLoader(0);
                $("#updatesuccessMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                    $("#updatesuccessMessageUser").slideUp(500);
                });
                setTimeout(function () {
                    $('#closemodal').click();
                    if (typeof onTrigger === 'function') {
                        onTrigger('');
                    }

                    navigate(`${pathLocation}${pathstring}`)

                }, 1000);
            }
            if (res.type == 'error') {
                $("#failuresuccessMessageUser").html(res.message);

                setShowLoader(0);
                $("#failuresuccessMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                    $("#failuresuccessMessageUser").slideUp(500);
                });
            }
        })

    }

    const assignProject = (forUser, userRoleVal) => {
        var projectid = [];
        for (var i = 0; i < optionProjectsSelected.length; i++) {
            projectid.push(optionProjectsSelected[i].value);
        }

        if (parseInt(userRoleVal) === 3) {

            //user
            const requestObject1 =
            {
                "projectIds": projectid,
                "userId": forUser
            }

            var accessTokenIn = sessionStorage.getItem("accToken");
            // const requestOptions1 = {
            // method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json',
            //     'Authorization': "Bearer "+accessTokenIn
            // },
            // body: JSON.stringify(requestObject1)
            // };
            services.assignProject(requestObject1).then((res) => {
                if (res.type === 'success') {
                    $("#entryform1").trigger("reset");
                    setIsOpen(false);
                    setShowLoader(0);
                    $("#successMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                        $("#successMessageUser").slideUp(500);
                    });
                    setTimeout(function () {
                        $('#closemodal').click();
                        if (typeof onTrigger === 'function') {
                            onTrigger('');
                        }

                        if (pathLocation === '/user-management') {
                            navigate('/user-management');
                        } else {
                            navigate('/projectDetail');
                        }
                    }, 1000);
                }
                if (res.type === 'error') {
                    $("#failuresuccessMessageUser").html(res.message);

                    setShowLoader(0);
                    $("#failuresuccessMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                        $("#failuresuccessMessageUser").slideUp(500);
                    });
                }
            });
        }

        if (parseInt(userRoleVal) === 2) {

            //manager
            const requestObjectManager = {
                "projectIds": projectid,
                "managerId": forUser
            }

            var accessTokenIn = sessionStorage.getItem("accToken");
            // const requestOptions1 = {
            // method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json',
            //     'Authorization': "Bearer "+accessTokenIn
            // },
            // body: JSON.stringify(requestObjectManager)
            // };
            services.assignProjectManager(requestObjectManager).then((res) => {
                if (res.type == 'success') {
                    $("#entryform1").trigger("reset");
                    setIsOpen(false);
                    setShowLoader(0);
                    $("#successMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                        $("#successMessageUser").slideUp(500);
                    });
                    setTimeout(function () {
                        $('#closemodal').click();
                        if (typeof onTrigger === 'function') {
                            onTrigger('');
                        }

                        if (pathLocation === '/user-management') {
                            navigate('/user-management');
                        } else {
                            navigate('/projectDetail');
                        }
                    }, 1000);
                }
                if (res.type == 'error') {
                    $("#failuresuccessMessageUser").html(res.message);

                    setShowLoader(0);
                    $("#failuresuccessMessageUser").fadeTo(2000, 500).slideUp(500, function () {
                        $("#failuresuccessMessageUser").slideUp(500);
                    });
                }
            });
        }
    }

    const getUsersWithCompany = (companyid, edituseridval) => {
        //load managers
        var usersResponse = '';
        var dummArray_users = [];
        const requestObject =
        {
            "roleId": ["2"],
            "status": '1',
            "companyId": companyid,
            "pageNumber": pageNum,
            "pageSize": pageSize
        }

        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //   },
        //   body: JSON.stringify(requestObject)
        // };
        services.getUsersByRole(requestObject).then((res) => {

            //setProjectManagerListInfo(res.data);
            var managersArrayVal = [];
            for (var m = 0; m < res.data.length; m++) {
                if (res.data[m].id !== edituseridval) {
                    managersArrayVal.push(res.data[m]);
                }
            }
            //managersArrayVal.filter(managerVal => managerVal.id !== edituseridval);
            setManagerListInfo(managersArrayVal);

            var serviceresponse = res.data;
            usersResponse = serviceresponse;
            var dummArray = [];
            if (!isEmpty(res.data)) {
                for (var i = 0; i < res.data.length; i++) {

                    if (res.data[i].id !== edituseridval) {
                        dummArray.push({ "label": res.data[i].first_name + ' ' + res.data[i].last_name, "value": res.data[i].id });
                    }


                    //dummArray_users.push({ "label": res.data.data[i].first_name+' '+res.data.data[i].last_name, "value": res.data.data[i].id });
                }
            }

            setmultiProjectManagers(dummArray);
        });
    }

    const getProjectsByCompany = (companyid) => {
        //load managers
        var usersResponse = '';
        var dummArray_users = [];
        const requestObject =
        {
            "companyId": companyid,
            "projectStatus": "1"
        }

        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //   },
        //   body: JSON.stringify(requestObject)
        // };
        services.getProjectsByCompany(requestObject).then((res) => {

            var serviceresponse = res.data;
            var usersResponse = serviceresponse;
            var dummArray = [];
            if (!isEmpty(res.data)) {
                for (var i = 0; i < res.data.length; i++) {
                    dummArray.push({ "label": res.data[i].name, "value": res.data[i].id });
                }
            }

            setmultiProjects(dummArray);
        });
    }

    const [multiProjects, setmultiProjects] = useState([]);
    function getProjectList(cientlistid, projectstatusval, sortingorder, pagenum, pagesize) {
        var projectstatus = 1;
        var managerid = '';
        services.getProjectsDetailsList(cientlistid, projectstatusval, sortingorder, pagenum, pagesize, managerid, '', '').then((res) => {
            if (res.data.data != null) {
                setShowProjectList(res.data.data);

                var serviceresponse = res.data.data;
                var usersResponse = serviceresponse;
                var dummArray = [];
                if (!isEmpty(res.data.data)) {
                    for (var i = 0; i < res.data.data.length; i++) {
                        dummArray.push({ "label": res.data.data[i].projectName, "value": res.data.data[i].projectId });
                    }
                }

                setmultiProjects(dummArray);
            }
        });
    }

    const [projectsRequestArray, setProjectsRequestArray] = useState([]);
    const [optionProjectsSelected, setoptionProjectsSelected] = useState([]);
    const handleChangeForProject = (selected) => {

        var dummArray = [];
        var dummArray_label = [];
        for (var i = 0; i < selected.length; i++) {

            dummArray.push(selected[i].value.toString());
            dummArray_label.push(selected[i].label.toString());
        }

        setProjectsRequestArray(dummArray);

        setoptionProjectsSelected(selected);

        //handleProjectChange(dummArray);
    };

    const getProjectswithCompany = (event) => {
        var companyid = event.target.value;
        setMyAdminCompany(companyid);
        getProjectsByCompany(companyid);
        getUsersWithCompany(companyid, '');

    }

    const handleProjectChange = (projectids) => {
        //var projectidval = event.target.value;
        var managerid = '';

        services.getProjectManagersByProjectId(projectids, managerid, '1', '', '').then((res) => {
            if (res.data.type == 'success') {

                setManagerListInfo(res.data.data);
            }
        });
    }

    const setReportingManager = (event) => {
        var managerid = event.target.value;
        setUserManager(managerid);
    }


    return (
        <>
            <div class="modal fade" id="createuser" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <form id="entryform1" onSubmit={handleSubmit(submitValue)}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">{getTitleHead()}</h5>
                                <button onClick={handleClose} type="button" id="closemodal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            {showLoader === 1 ?
                                <div class="loadwrapperpopup">
                                    <div class="clock"><div class="circle"></div></div>
                                </div>
                                : ''}

                            <div class="modal-body">

                                <div class="d-flex justify-content-between">
                                    <div class="form-group mb-2">
                                        <label for="recipient-name" class="col-form-label">First Name*</label>
                                        <input type="text" className={`form-control ${firstNamevalidation === 1 ? 'errorinp' : ''}`} id="userfirst_name" name="userfirst_name" placeholder="First Name" />
                                    </div>
                                    <div class="form-group mb-2">
                                        <label for="recipient-name" class="col-form-label">Last Name*</label>
                                        <input type="text" className={`form-control ${lastNamevalidation === 1 ? 'errorinp' : ''}`} id="userlast_name" name="userlast_name" placeholder="Last Name" />
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label for="recipient-name" class="col-form-label">Email Address *</label>
                                    <input type="text" className={`form-control ${emailvalidation === 1 ? 'errorinp' : ''}`} id="user_email" name="user_email" placeholder="Email Address " disabled={isEditMode} />
                                    {emailvalidation === 1 && (
                                        <small className="text-danger">{emailValidationMessage}</small> // Dynamic error message
                                    )}
                                </div>
                                <div class="row">
                                    <div class="form-group mb-2 col-md-6">
                                        <label for="recipient-name" class="col-form-label">Designation*</label>
                                        <select class={`form-select ${designationvalidaion === 1 ? 'errorinp' : ''}`} aria-label="Default select example" id="user_designation" name="user_designation">
                                            <option value='' selected>Select Designation</option>
                                            {userDesignations.map((designationData, designationIndex) => (
                                                <option value={designationData.id}>{designationData.designation_title}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div class="form-group mb-2 col-md-6">
                                        <label for="recipient-name" class="col-form-label">Role*</label>
                                        <select class={`form-select ${userrolevalidaion === 1 ? 'errorinp' : ''}`} aria-label="Default select example" id="user_role" name="user_role" >
                                            <option value="" selected>Select Role</option>

                                            <option value="2">Manager</option>
                                            <option value="3">User</option>
                                        </select>
                                    </div>

                                    <div class="form-group mb-2 col-md-6">
                                        <label for="recipient-name" class="col-form-label">Company*</label>
                                        <select class={`form-select ${companyvalidaion === 1 ? 'errorinp' : ''}`} aria-label="Default select example" onChange={getProjectswithCompany} id="companyname" value={myAdminCompany} name="companyname" disabled>
                                            <option value="">Select Company</option>
                                            {fullCompanies.map((companyData, companyIndex) => (
                                                <option value={companyData.id}>{companyData.company_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div class="form-group mb-2 col-md-6">
                                        <label for="recipient-name" class="col-form-label">Employee ID*</label>
                                        <input type="text" class={`form-control ${employeeidvalidaion === 1 ? 'errorinp' : ''}`} id="employeeid" name="employeeid" placeholder="Employee id" />
                                    </div>

                                </div>

                                <div class="form-group mb-2 d-grid">
                                    <label for="recipient-name" class="col-form-label">Projects</label>


                                    <ReactSelect
                                        //class="form-select"
                                        options={multiProjects}
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{
                                            Option
                                        }}
                                        onChange={handleChangeForProject}
                                        allowSelectAll={false}
                                        value={optionProjectsSelected}
                                        defaultValue={['1']}
                                    />
                                </div>

                                <div class="form-group mb-2 d-grid">
                                    <label for="recipient-name" class="col-form-label">Reporting Manager</label>
                                    <select class={`form-select `} id="manager_name" aria-label="Default select example" value={userManager} onChange={setReportingManager}>
                                        <option value="" selected>Select Manager</option>
                                        {managerListInfo.map((managerData, managerIndex) => (
                                            <option value={managerData.id}>{managerData.first_name + ' ' + managerData.last_name}</option>
                                        ))}
                                    </select>
                                </div>
                                {showValidation == 1 ?
                                    <div className="validationglobal p-2" id="editvalidationMessage">
                                        <div className="validateitem">
                                            {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                                            <p>Please fill out all the required fields!</p>
                                        </div>
                                    </div>
                                    : ''}
                            </div>


                            <div class="modal-footer">
                                <div class="d-flex justify-content-between w-100">
                                    <div className="d-flex gap-2">
                                        <button type="submit" class="btn btn-primary">{(!isEmpty(editUser)) ? 'Update' : 'Add User'}</button>

                                        <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>
                                    </div>

                                </div>

                            </div>
                            <div class="px-3">
                                <div id="successMessageUser" class="alert alert-success" role="alert">User Created successfully!</div>
                                <div id="updatesuccessMessageUser" class="alert alert-success" role="alert">User Updated successfully!</div>
                                <div id="failuresuccessMessageUser" class="alert alert-danger" role="alert"></div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

        </>
    )
};

AddUser.propTypes = {};

AddUser.defaultProps = {};

export default AddUser;
